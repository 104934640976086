<template lang="pug">
  .actions
    AppIconButton.action-delete(
      useAppIcon
      icon="trash"
      @click="removeShop"
    )
    AppIconButton.action-open(
      title=""
      :disabled="!isSelectedCarsAndShops"
      :icon="isSourcesCarClassOpened ? 'chevron-up' : 'chevron-down'"
      @click="$emit('toggle-car-class-open')"
    )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { findIndex } from "lodash-es"

  export default {
    props: {
      isSourcesCarClassOpened: Boolean,
      isSelectedCarsAndShops: Boolean,
      isSelectedCar: Boolean,
      localShop: Object,
      shopsDeleted: Array,
      selectedShops: Array,
      shopsAdded: Array,
      sourcesCar: Object
    },

    mixins: [withConfirmation],

    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    methods: {
      removeShop() {
        this.$confirm({
          title: this.$t("car_class_matching.delete_confirmation", { name: this.localShop.name }),
          resolve: {
            handler: () => {
              this.removeShopSourcesCarMatching()
            }
          }
        })
      },

      removeShopSourcesCarMatching() {
        if (!this.localShop.id) return this.shopsAdded.splice(this.index, 1)

        if (this.localShop._addedAt) {
          this.removeShopFromAdded()
        } else {
          this.addShopToDeleted()
          this.removeShopFromSelected()
        }

        this.unmatchCarOnDeleting()
        this.$emit("remove-sources-car-classes")
      },

      unmatchCarOnDeleting() {
        const carInShopIndex = findIndex(this.localShop.sources_cars, { id: this.sourcesCar.id })
        this.localShop.sources_cars.splice(carInShopIndex, 1)

        if (this.isSelectedCar) {
          this.$emit("remove-sources-car", this.sourcesCar)
        }
      },

      addShopToDeleted() {
        const shopDeletedIndex = findIndex(this.shopsDeleted, { id: this.sourcesCar.shop_id })

        if (shopDeletedIndex !== -1) {
          this.shopsDeleted[shopDeletedIndex]["sources_cars"].push(this.sourcesCar)
        } else {
          this.shopsDeleted.push({ id: this.sourcesCar.shop_id, sources_cars: [this.sourcesCar] })
        }
      },

      removeShopFromAdded() {
        const shopIndex = findIndex(this.shopsAdded, { _addedAt: this.localShop._addedAt })

        if (shopIndex !== -1) this.shopsAdded.splice(shopIndex, 1)
      },

      removeShopFromSelected() {
        const shopIndex = findIndex(this.selectedShops, { id: this.localShop.id })
        if (shopIndex === -1) return

        if (this.selectedShops[shopIndex].sources_cars.length <= 1) {
          this.selectedShops.splice(shopIndex, 1)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"

  .actions
    display: inline-flex
    padding-top: 11px
    justify-content: space-between
    width: 60px
    height: 100%

    .action-delete,
    .action-open
      border: none
      +icon-button($default-purple)
</style>
